<template>
  <div>
    <h1>Cidades</h1>

    <v-btn color="error" title="Voltar" @click="$router.back()" class="ma-2">
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-btn
      color="success"
      title="Adicionar Cidade"
      @click="$router.push('/adicionar-cidade')"
      class="ma-2"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn
      color="primary"
      title="Editar cidades com taxa"
      @click="$router.push({ name: 'Cities.editTda' })"
      class="ma-2"
    >
      Editar TDA
    </v-btn>

    <v-btn
      color="secondary"
      title="Exibir relação de praças"
      @click="$router.push({ name: 'Cities.details' })"
      class="ma-2"
    >
      Praças
    </v-btn>

    <v-btn
      title="Ir para cadastro de feriados"
      @click="$router.push({ name: 'holidays.index' })"
      class="ma-2"
    >
      Feriados
    </v-btn>

    <v-data-table
      :items="cities"
      :headers="headers"
      disable-pagination
      hide-default-footer
      dense
      :loading="cities.length < 1"
    >
      <template v-slot:[`item.regions.name`]="{ item }">
        <span>{{ item.regions ? item.regions.name : "Nenhuma" }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-icon
            color="warning"
            title="Editar"
            @click="$router.push(`editar-cidade/${item.id}`)"
          >
            mdi-pencil
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cities: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Região", value: "regions.name" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  mounted() {
    this.getCities();
  },
  methods: {
    getCities() {
      this.$http
        .get("api/cities")
        .then((res) => {
          this.cities = res.data;
        })
        .catch((err) => {
          console.log(err);

          this.$side({
            type: "error",
            msg: "Erro ao carregar as cidades",
            duration: 4000,
          });
        });
    },
  },
};
</script>

<style scoped></style>
